.App {
  text-align: center;
  /* background: linear-gradient(180deg, rgba(50, 0, 75, 0.8) -21.58%, rgba(47, 4, 68, 0.8) 37.25%, rgba(47, 4, 68, 0.8) 72.12%, rgba(47, 4, 68, 0.8) 87.65%, rgba(47, 4, 68, 0.8) 103.59%); */
  /* height: 100vh; */
  background-image: url('./assests/images/Background-banner.png');
  background-size: cover;
}

/* Navbar container  */

.navbar-container,.banner-container{
  width: 100%;
  display: flex;
  justify-content: center;
  /* height: 150px; */
}
.navbar-div,.banner-div{
  width: 90%;
  display: flex;
  justify-content: space-between;
  padding: 40px 0px;
  align-items: center;
}
.navbar-div img{
height: 40px;
}
.navbar-section2{
  width: 49%;
  display:flex;
  justify-content:space-between
}
.navbar-section3{
  display: none;
 }
@media screen and (max-width: 1200px) {
  .navbar-section2{
    width: 55%;
  }
}
@media screen and (max-width: 920px) {
  .navbar-section2{
   display: none;
  }
  .navbar-section3{
    /* width: 49%; */
    display:flex;
    justify-content:space-between
  }
  .navbar-div img{
    height: 35px;
  }
}
/* banner section  */
.banner-div{
  width: 80%;
}
.banner-img{
  width: 38%;
}
.banner-img img{
  width: 100%;
  height: 450px;
}
.banner-content{
  width: 59%;
  /* display: flex; */
    flex-direction: column;
    align-items: flex-start;
    color: #fff;
    justify-content: space-between;
}
.banner-content h2{
  font-size: 25px;
  text-align: left;
  font-family: MontserratBold;
}
.banner-content p{
  text-align: left;
  /* width: 90%; */
  font-size: 20px;
  line-height: 30px;
  font-family: MontserratRegular;
}
.banner-content span{
  text-align: left;
  font-weight: bold;
}
.banner-btn {
  width: 35%;
  height: 50px;
}
/* owner section  */
.owner-container{
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #fff;
  align-items: center;
  flex-direction: column;
}
.owner-div{
  width: 90%;
  display: flex;
  justify-content: center;
  background-color: #fff;
  /* height: 400px; */
  margin-top: 70px;
  flex-direction: column;
  align-items: center;
}
.owner-div h2{
  font-size: 40px;
  text-align: center;
  color: #380351;
  font-family: MontserratBold;
}

.owner-div p{
  font-size: 19px;
  text-align: center;
  color: #1A212C;
  font-family: MontserratRegular;
  font-weight: 400;
}
.owner-section-img{
  display: flex;
  justify-content: space-between;
  height: 450px;
}
.owner-section-img div{
  width: 48%;
  height: 100%;
  /* background: linear-gradient(180deg, rgba(50, 0, 75, 0.8) -21.58%, rgba(47, 4, 68, 0.8) 37.25%, rgba(47, 4, 68, 0.8) 72.12%, rgba(47, 4, 68, 0.8) 87.65%, rgba(47, 4, 68, 0.8) 103.59%); */
  border-radius: 5px;
  padding: 0px 5px;
}
.owner-section-img img{
  width: 100%;
  height: 100%;
}
.owner-section-img2{ 
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 50px 0px;
}
.owner-section-img2 img{
  margin: 10px 20px;
  border-radius: 10px;
}
.owner-section-img2 h3{
  font-size: 18px;
  color: #380351;
  text-align: center;
  margin-top: 10px;
  font-family: MontserratBold;
  line-height: 25px;
}
.discord-para{
  color: #1A212C !important;
  font-family: MontserratBold !important;
  
}
.btndivstyle >div{
width: 27%;
}
.discord-div{
  width: 75%;
  box-shadow: 0px 3px 10px 2px #38035140;
  border-radius: 10px;
  background-color: #380351;
}
@media screen and (max-width: 1430px) {
  .banner-content p{
    font-size: 17px;
  }
  .banner-btn {
    width: 40%;
  }
}
@media screen and (max-width: 1230px) {
  .banner-content p{
   line-height: 27px;
  }
}
@media screen and (max-width: 1140px) {

  .banner-content p{
    font-size: 15px !important;
   line-height: 23px!important;
  }
  .banner-content h2 {
    font-size: 22px;
  }
}
@media screen and (max-width: 1020px) {
  
  .banner-content p{
    font-size: 14px !important;
   line-height: 21px!important;
  }
  .banner-content h2 {
    font-size: 20px;
  }
  .banner-btn {
    width: 45%;
  }
}
@media screen and (max-width: 992px) {
  .banner-div{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .banner-content{
    width: 100%;
    margin-top: 50px;
  }
  .banner-btn {
    width: 45%;
  }
  .banner-content h2{
    font-size: 23px
  }
.banner-content p{
  font-size: 17px
}
.banner-img{
  width: 95%;
}
}
@media screen and (max-width: 768px) {
  .owner-div h2{
    font-size: 30px;
  }
  .owner-div p{
    font-size: 14px;
  }
  .discord-para{
    margin-top: 15px;
  }
  .owner-section-img{
    flex-direction: column;
    align-items: center;
    height: auto;
    margin: 5px 0px;
  }
  .owner-section-img div{
    width: 80%;
    margin-bottom: 10px;
  }
  .owner-div{
    margin-top: 30px;
  }
  .owner-section-img2{
    margin: 10px 0px;
  }
 
}
@media screen and (max-width: 670px) {
  .owner-section-img2{
    justify-content: center;
  }
  .banner-btn {
    width: 80% !important;
  }
  .banner-content h2{
    font-size: 21px
  }
.banner-content p{
  font-size: 15px
}
.navbar-div{
  padding: 15px 0px;
}
.navbar-div img {
  height: 40px;
}
.banner-div{
  padding: 5px 0px 25px 0px;
}
}
@media screen and (max-width: 450px) {
  .banner-content {
    /* width: 100%; */
    margin-top: 25px;
}
.banner-img img{
  height: 375px;
}
}
@media screen and (max-width: 400px) {
  .banner-btn {
    width: 100% !important;
  }
  .banner-content h2{
    font-size: 18px
  }
.banner-content p{
  font-size: 13px
}
.discord-div{
  width: 95%;
}
.banner-content {
  /* width: 100%; */
  margin-top: 15px;
}
  }


/* footer css  */

.footer {
  /* background: linear-gradient(180deg, rgba(50, 0, 75, 0.8) -21.58%, rgba(47, 4, 68, 0.8) 37.25%, rgba(47, 4, 68, 0.8) 72.12%, rgba(47, 4, 68, 0.8) 87.65%, rgba(47, 4, 68, 0.8) 103.59%); */
  padding: 60px 0px;
  margin-top: 50px;
  background-image: url('./assests/images/Footer-Background.png');
  background-size: cover;
}

.logo_div h2 {
  color: #fff;
  padding-left: 5px;
  font-size: 25px;
  padding-top: 15px;
}

#left {
  align-items: center;
  vertical-align: middle;
}

#right {
  /* padding-top: 42px; */
  align-items: center;
  vertical-align: middle;
}

#left #p {
  display: flex;
  justify-content: flex-start;
  color: #fff;
  text-align: left;
  margin-bottom: 0px;
  font-family: var(--Para);
  font-size: 13px;
}

#left .p {
  width: 80%;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  font-family: var(--Para);
  font-size: 15px;
}

#right #p {
  color: #fff;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: 10px;
  font-family: var(--Para);
  font-size: 15px;
}

#right .p {
  width: 80%;
  color: #fff;
  text-align: left;
  display: flex;
  margin-bottom: 22px;
  justify-content: flex-start;
  font-family: var(--Para);
  font-size: 15px;
}

.sociallinks {
  display: flex;
  justify-content: left;
  margin-top: 35px;
}

.sociallinks img {
  margin-right: 8px;
}
.footer-img-div{
  display: flex;
  flex-direction: column;
  /* margin-top: -15px; */
}
.copyright-box{
  display: flex;
}
.copyright-box1{
  display: none;

}
@media screen and (max-width: 768px) {
.footer-img-div{
  align-items: flex-start !important;
  margin-top: 50px !important;
}
.copyright-box{
  display: none;
}
.copyright-box1{
  display: flex;
  align-items: center;
}
#right{
  margin-top: 10px;
}
}
@media screen and (max-width: 600px) {
  #right .p {
    margin-top: 10px;
    width: 100%;
  }
  #left .p {
    width: 100%;
  }
  #left {
    padding-bottom: 20px;
    /* border-bottom: 1px solid #fff; */
    border-width: 90%;
  }
  .sociallinks img {
    margin-right: 10px;
  }
  #right #p{
    font-size: 13px;
  }
}
